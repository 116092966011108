.maintenance {
  overflow: hidden;
  position: relative;
  font-size: 1em;
  background: linear-gradient(-18deg, #252627 60%, #525358 100%);
  height: 100vh;
}

.maintenance::before {
  content: "";
  display: block;
  position: absolute;
  top: 25%;
  left: -10%;
  background: linear-gradient(-18deg, #252627 6%, #525358 100%);
  transform: skewY(-18deg) scaleY(1) translateY(14%);
  width: 120%;
  height: 65%;
  opacity: 1;
}

.partnerlogos {
  position: absolute;
  bottom: 6%;
  left: 50%;
  width: 300px;
  margin: 0 -150px;
  display: flex;
  justify-content: space-around;
}

.partnerlogos > span {
  position: absolute;
  width: 100%;
  top: -1.5em;
  text-align: center;
  font-size: 0.875em;
  color: rgba(255, 255, 255, .6);
}

.logo {
  position: absolute;
  top: 58px;
  left: 50%;
  z-index: 2;
  width: 250px;
  margin: 0 -125px;
  text-align: center;
}

.logo h1 {
  width: 250px;
  margin: 1em auto;

  font-size: 1em;
  line-height: 1.2em;
  letter-spacing: 5px;
  animation: fade 2s 0s ease-out 1;
}

.logo h1 span {
  display: block;
  font-size: .85em;
  letter-spacing: .25em;
  color: rgba(255, 255, 255, .7);
}

.logo svg {
  width: 52px;
  margin: 0 25px;
  animation: zoom 2s ease-out 1;
}

.message {
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 39%;
  width: 100%;
}

.message h3 {
  font-size: 2.5em;
  line-height: 1.05em;
}

@media screen and (max-width: 920px) {
  .partnerlogos {
    bottom: 10px;
  }

  .message {
    text-align: center;
    width: 340px;
    margin: 0 -170px;
    left: 50%;
  }

  .message h3 {
    font-size: 1.7em;
    max-width: unset;
  }
}
